import './App.css';
import Home from './pages/Home';
import BuySellBoatsLayout from './pages/BuySellBoatsLayout';
import { HashRouter as Router, Routes, Route } from 'react-router-dom'; // Use HashRouter
import ListingDetails from 'components/ListingDetails';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/buysellboats" element={<BuySellBoatsLayout />} />
        <Route path="/listingdetails" element={<ListingDetails />} />

      </Routes>
    </Router>
  );
}

export default App;
