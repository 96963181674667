import React from 'react';
import Header from '../components/Header';
import SearchHeader from '../components/SearchHeader';
import PopularCategories from '../components/PopularCategories';
import Card from '../components/Card';
import Footer from '../components/Footer';
import AdBanner from 'components/AdBanner';

const Home = () => {
  const cardsData = [
    {
      image: 'https://nanjemarineservice.com/wp-content/uploads/elementor/thumbs/azimut-78-feet-1-scaled-qsbchhpb1wfzh8ggdy5br45nswzto91s37ynmzvbao.jpeg',
      title: 'AZIMUT 78 Feet Dubai',
     
    },
    {
      image: 'https://nanjemarineservice.com/wp-content/uploads/elementor/thumbs/singapore-made-yacht-1-qrlbulkzqjtm2p1219sd0gks5j4fcwdui777qd7gts.jpeg',
      title: 'SINGAPORE MADE 47 Feet 2006',
     
    },
    {
      image: 'https://nanjemarineservice.com/wp-content/uploads/elementor/thumbs/Gulf-craft-1-qnt3yr01d02sj10drg82s6cu7zkj5qf3j2ma5klo8g.jpeg',
      title: 'GULF CRAFT AMBASSADOR Dubai',
     
    },
    {
      image: 'https://nanjemarineservice.com/wp-content/uploads/elementor/thumbs/Cranchi-Altlantique-2-qr6tamqwkjnnjigmpp16zlf3zcx0ji6dfls3hlwdm8.jpeg',
      title: 'Cranchi Altlantique 43 Feet 2008',
     
    },
  ];
  const cardsDataSports = [
    {
      image: 'https://mybayutcdn.bayut.com/mybayut/wp-content/uploads/Jet-Skiing-in-Dubai.jpg',
      title: 'Jet Skiing',
    },
    {
      image: 'https://media1.thrillophilia.com/filestore/89t16rhzq39hga8gw7daqyj9eijo_p1.jpg',
      title: 'Scuba Diving',
     
    },
    {
      image: 'https://mybayutcdn.bayut.com/mybayut/wp-content/uploads/Man-on-boat-1079236580-1024x640.jpg',
      title: 'Stand-up Paddle Boarding',
   
    },
    {
      image: 'https://mybayutcdn.bayut.com/mybayut/wp-content/uploads/Boy-enjoying-131936108-1024x640.jpg',
      title: 'The Donut Ride',
     
    },
  ];
  const cardsDataFishing = [
    {
      image: 'https://media.tacdn.com/media/attractions-splice-spp-674x446/07/29/e3/8c.jpg',
      title: 'Dubai Deep Sea Fishing',
     
    },
    {
      image: 'https://dbz-images.dubizzle.com/images/2024/07/29/221434a5ac6b4c198ee9c588d8a5c730-.jpeg?impolicy=dpc',
      title: 'Silver Craft 36HT Fishing Boat',
     
    },
    {
      image: 'https://www.easyyacht.ae/wp-content/uploads/2023/02/fishing3.jpeg',
      title: 'Fishing boat',
     
    },
    {
      image: 'https://thumbs.dreamstime.com/b/motor-boat-impressive-navigation-37924770.jpg',
      title: 'Redac Gateway Hotel',
     
    },
  ];
  const cardsDataRentals = [
    {
      image: 'https://lsb-charter.com/wa-data/public/shop/products/92/00/92/images/2152/2152.750x0.jpg',
      title: 'Benetti 115ft Gallus',
     
    },
    {
      image: 'https://images.squarespace-cdn.com/content/v1/5ce72471a5c3bd0001d02624/1620326033559-B3U1EZ5RN8RN6R6FADOO/1.jpg',
      title: 'Sunseeker 82 Why Not',
     
    },
    {
      image: 'https://images.boatsgroup.com/images/1/26/26/8832626_0_010720231014_6.png',
      title: 'Rent Azimut 68 Princess',
   
    },
    {
      image: 'https://lsb-charter.com/wa-data/public/shop/products/78/00/78/images/2189/2189.890x500.jpg',
      title: 'Azimut 75 Viktoria',
     
    },
  ];

  return (
    <div className="home">
      <Header />
      <main className="main-content container mt-2">
        <div className='container'>
        <SearchHeader />
        </div>

        <div>
          <PopularCategories />
        </div>
        <div className="buy-sell-section container">
            <h2 className="section-heading">Buy & Sell Boats</h2>
            <div className="cards-container">
        {cardsData.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            location={card.location}
            rating={card.rating}
            reviews={card.reviews}
            price={card.price}
          />
        ))}
       </div>
        </div>
        <div className="buy-sell-section container">
            <h2 className="section-heading">Fishing </h2>
            <div className="cards-container">
        {cardsDataFishing.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            location={card.location}
            rating={card.rating}
            reviews={card.reviews}
            price={card.price}
          />
        ))}
      </div>
        </div>
        <div className="buy-sell-section container">
            <h2 className="section-heading">Boat Rentals</h2>
        <div className="cards-container">
        {cardsDataRentals.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
            location={card.location}
            rating={card.rating}
            reviews={card.reviews}
            price={card.price}
          />
        ))}
      </div>
        </div>
        <div className="buy-sell-section container">
            <h2 className="section-heading">Water Sports</h2>
            <div className="cards-container">
        {cardsDataSports.map((card, index) => (
          <Card
            key={index}
            image={card.image}
            title={card.title}
           
          />
        ))}
      </div>
       </div>
       <AdBanner size="large" image="https://media.istockphoto.com/id/1212816534/photo/luxurious-motor-boat.jpg?s=612x612&w=0&k=20&c=gMQmBcZAeLO8R2-wPW9jNuFQyIr4vyqrjAO6xnowqlA=" />

      </main>
     

        
      <Footer />
    </div>
  );
};

export default Home;