import React from 'react';
import { Link } from 'react-router-dom';


const PopularCategories = () => {
  return (
    <div className="popular-categories">
      <h2 className='popular-cat' style={{fontWeight:'bold', marginBottom:'30px'}}>Popular Boats Categories</h2>
      <div className="row ">
      <div className="col-md-6 mb-2">
  <Link to="/buysellboats" className="category-link" style={{ textDecoration: 'none' }}>
    <div className="category large" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/buy-boats.webp)` }}>
      <span className="category-name">Buy & Sell Boats</span>
      <div className="category-dropdown">
        <a href="#" className="dropdown-item">- View Boats</a>
        <a href="#" className="dropdown-item">- Sell your boat</a>
      </div>
    </div>
  </Link>
</div>
         <div className="col-md-6 mb-2">
          <div className="category large"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/rental-boats.webp)`}}>
            <span className="category-name">Rental Boats</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- View rentals</a>
              <a href="#" className="dropdown-item">- Pricing Guide</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mb-2">
          <div className="category small" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/marinas.webp)`}}>
            <span className="category-name">Marinas</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- Find Marinas</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mb-2">
          <div className="category small" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/fishing.webp)`}}>
            <span className="category-name">Fishing</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- Fishing Tours</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mb-2">
          <div className="category small" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/water-sports.webp)`}}>
            <span className="category-name">Water Sports</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">Activities:</a>
              <a href="#" className="dropdown-item">- Parasailing</a>
              <a href="#" className="dropdown-item">- Kayaking</a>
              <a href="#" className="dropdown-item">- Water Jet</a>



            </div>
          </div>
        </div>
        <div className="col-md-6 col-xl-3 mb-2">
          <div className="category small" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/boat-tours.webp)`}}>
            <span className="category-name">Boat Tours</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- Book a Tour</a>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="category large"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/scuba.jpg)`}}>
            <span className="category-name">Scuba Section</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- Rentals</a>
              <a href="#" className="dropdown-item">- Courses</a>
              <a href="#" className="dropdown-item">- Equipment</a>

            </div>
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="category large"  style={{backgroundImage: `url(${process.env.PUBLIC_URL}/fishing-equipment.jpg)`}}>
            <span className="category-name">Fishing Equipment Sales</span>
            <div className="category-dropdown">
              <a href="#" className="dropdown-item">- Lures</a>
              <a href="#" className="dropdown-item">- Lines</a>
              <a href="#" className="dropdown-item">- Bait</a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCategories;