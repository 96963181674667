import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ImageIcon from '@mui/icons-material/Image';

const BoatCard = ({ image, title, price, age, length, location, category }) => {
  return (
    <Box sx={{ 
      display: 'flex', 
      mb: 2, 
      pb: 2,
      borderBottom: '1px solid #e0e0e0'
    }}>
      <Box sx={{ position: 'relative', mr: 2 }}>
        <Box
          component="img"
          src={image}
          alt={title}
          sx={{
            width:' 280px',
            height: '200px',
            borderRadius: '8px',
            objectFit: 'cover'
          }}
        />
        <IconButton 
          sx={{ position: 'absolute', top: 8, right: 40, color: 'white' }}
          size="small"
        >
          <ShareIcon />
        </IconButton>
        <IconButton 
          sx={{ position: 'absolute', top: 8, right: 8, color: 'white' }}
          size="small"
        >
          <FavoriteBorderIcon />
        </IconButton>
        <Box sx={{ 
          position: 'absolute', 
          bottom: 8, 
          left: 8, 
          backgroundColor: 'rgba(0,0,0,0.6)', 
          color: 'white',
          padding: '2px 4px',
          borderRadius: '4px',
          display: 'flex',
          alignItems: 'center'
        }}>
          <ImageIcon sx={{ fontSize: 16, mr: 0.5 }} />
          <Typography variant="caption">10</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {category}
          </Typography>
          <Typography variant="h5" color="text.primary" gutterBottom sx={{ fontWeight: 'bold' }}>
            AED {price}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Age: {age} | Length: {length}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <LocationOnIcon fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
          <Typography variant="body2" color="text.secondary">{location}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BoatCard;