import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import AdBanner from './AdBanner';


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
      <div className="top-header">
        <div className="container d-flex justify-content-between align-items-center">
          <div style={{marginRight:'15px'}} className="logo">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" className="logo-img"  />
          </div>
          <AdBanner size="medium" image="https://xclusiveboatclub.com/wp-content/uploads/2024/08/third-header-webp.webp" />

          <nav className="top-nav">
            <ul className="list-unstyled  d-flex mb-0" style={{width:'max-content'}}>
              <li><a href="#" className="nav-link"><i className="fas fa-bell"></i> Notifications</a></li>
              <li><a href="#" className="nav-link"><i className="fas fa-search"></i> My Searches</a></li>
              <li><a href="#" className="nav-link"><i className="fas fa-heart"></i> Favorites</a></li>
              <li><a href="#" className="nav-link"><i className="fas fa-comment"></i> Chats</a></li>
            </ul>
          </nav>
          <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
            <GiHamburgerMenu size={24} />
          </button>
        </div>
      </div>
      <nav className={`main-nav ${isMobileMenuOpen ? 'mobile-open' : ''}`}>
      <div className="container">
  <ul className="list-unstyled d-flex justify-content-between mb-0">
    <li><Link to="/" className="nav-link">Home</Link></li>
    <li><Link to="/buysellboats" className="nav-link">Buy & Sell Boats <span className="badge bg-success">NEW</span></Link></li>
    <li><Link to="/tips-advice" className="nav-link">Tips & Advice</Link></li>
    <li><Link to="/marinas" className="nav-link">Marinas</Link></li>
    <li><Link to="/fishing" className="nav-link">Fishing</Link></li>
    <li><Link to="/watersports" className="nav-link">Water Sports</Link></li>
    <li><Link to="/scuba" className="nav-link">Scuba</Link></li>
  </ul>
</div>
      </nav>
    </header>
  );
};

export default Header;