import React from 'react';

const Card = ({ image, title, location, rating, reviews, price }) => {
  return (
    <div className="card">
      <div className="card-image-container">
        <img src={image} alt={title} className="card-image" />
        
      </div>
      <div className="card-content">
        <h3>{title}</h3>
       
        <div className="card-price">
          <p>Lorem ipsum dolor sit amet consectetur?</p>
        </div>
      </div>
    </div>
  );
};

export default Card;