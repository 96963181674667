import React, { useState } from 'react';
import { Box, Button, Container, Typography, Paper } from '@mui/material';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsShare } from 'react-icons/bs';
import { BiMessageDetail } from 'react-icons/bi';
import { FiPhone } from 'react-icons/fi';
import { MdVerified } from 'react-icons/md';
import Header from './Header';

const ListingDetails = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  const images = [
    'https://scoutboats.com/wp-content/uploads/2021/03/235dorado1.jpg',
    'https://scdn.malibuboats.dev/cdn.pursuitboats.com/images/HomeNews/OS325_Sizzle_6-24-70.webp',
    'https://www.yamahaboats.com/globalassets/media/my25/boats/home/boats-homepage-s-curve/s-curve-1-aesthetics-that-excite-you.png',
    'https://www.boatingmag.com/uploads/2022/12/BTGBG23-RUN-Starcraft-SVX-230-IO-03-edit.jpg',
    'https://www.yamahaboats.com/globalassets/media/my25/boats/home/boats-homepage-s-curve/s-curve-1-aesthetics-that-excite-you.png',
    'https://scoutboats.com/wp-content/uploads/2021/03/235dorado1.jpg',
    'https://scdn.malibuboats.dev/cdn.pursuitboats.com/images/HomeNews/OS325_Sizzle_6-24-70.webp',
    'https://scoutboats.com/wp-content/uploads/2021/03/235dorado1.jpg'
  ];


  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <>
     <Header />
    <Container maxWidth="lg" sx={{ py: 3 }} style={{marginTop:'15rem'}}>
    
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', mb: 2 }}>
        <Box>
          <Typography variant="h4" sx={{ 
            fontWeight: 'bold', 
            fontSize: { xs: '1.5rem', md: '2rem' }
          }}>
            Jengshang (128ft) ONLY ONE IN UAE
          </Typography>
          <Typography color="text.secondary" sx={{ mt: 0.5 }}>
            Posted 6 days ago
          </Typography>
        </Box>
        <Typography 
          variant="h4" 
          sx={{ 
            color: '#dc2626', 
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', md: '2rem' }
          }}
        >
          AED 2,600,000
        </Typography>
      </Box>

      {/* Main Content Layout */}
      <Box sx={{ display: 'flex', gap: 3 }}>
        {/* Left Side - Images Section */}
        <Box sx={{ flex: '1 1 70%' }}>
          {/* Main Image Carousel */}
          <Box sx={{ 
            position: 'relative', 
            width: '100%',
            height: '500px',
            mb: 2,
            bgcolor: '#000',
            borderRadius: 1,
            overflow: 'hidden'
          }}>
            <img
              src={images[currentImageIndex]}
              alt="Boat view"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
            
            {/* Navigation Arrows */}
            <Box sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
            }}>
              <Button
                onClick={handlePrevImage}
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                }}
              >
                <IoIosArrowBack size={24} />
              </Button>
              <Button
                onClick={handleNextImage}
                sx={{
                  minWidth: '40px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  bgcolor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                }}
              >
                <IoIosArrowForward size={24} />
              </Button>
            </Box>

            {/* Favorite & Share Buttons */}
            <Box sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              display: 'flex',
              gap: 1
            }}>
              <Button
                variant="contained"
                startIcon={<AiOutlineHeart />}
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                }}
              >
                Favorite
              </Button>
              <Button
                variant="contained"
                startIcon={<BsShare />}
                sx={{
                  bgcolor: 'white',
                  color: 'black',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' },
                }}
              >
                Share
              </Button>
            </Box>
          </Box>

          {/* Thumbnail Strip */}
          <Box sx={{
            display: 'flex',
            gap: 1,
            overflowX: 'auto',
            mb: 3,
            pb: 1,
            '::-webkit-scrollbar': {
              height: '6px',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '3px',
            }
          }}>
            {images.map((img, idx) => (
              <Box
                key={idx}
                onClick={() => setCurrentImageIndex(idx)}
                sx={{
                  flexShrink: 0,
                  width: '100px',
                  height: '75px',
                  borderRadius: 1,
                  overflow: 'hidden',
                  cursor: 'pointer',
                  border: currentImageIndex === idx ? '2px solid #1976d2' : 'none',
                }}
              >
                <img
                  src={img}
                  alt={`Thumbnail ${idx + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        {/* Right Side - Seller Info */}
        <Box sx={{ flex: '1 1 30%', maxWidth: '350px' }}>
          <Paper elevation={0} sx={{ 
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            p: 3,
          }}>
            <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 2 }}>
              Posted by
            </Typography>
            
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Typography variant="h6">Hugo</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <MdVerified color="#1976d2" size={16} />
                  <Typography variant="caption" sx={{ color: '#1976d2', fontWeight: 500 }}>
                    VERIFIED USER
                  </Typography>
                </Box>
              </Box>
              <Typography color="text.secondary" variant="body2" sx={{ mb: 0.5 }}>
                Joined on September 2023
              </Typography>
              <Typography color="text.secondary" variant="body2">
                1 Items live
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<FiPhone />}
                sx={{
                  bgcolor: '#dc2626',
                  '&:hover': { bgcolor: '#b91c1c' },
                  py: 1.5
                }}
              >
                Show Phone Number
              </Button>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<BiMessageDetail />}
                sx={{ 
                  borderColor: '#e0e0e0', 
                  color: 'text.primary',
                  py: 1.5,
                  '&:hover': {
                    borderColor: '#bdbdbd',
                    bgcolor: 'transparent'
                  }
                }}
              >
                Chat with Seller
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>

      {/* Item Overview Section */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
          Item overview
        </Typography>
      </Box>
    </Container>
    </>
   
  );
};

export default ListingDetails;